import React from "react"
import Layout from "../components/layouts/Layout"
import { Hero, SliceRenderer, Map } from "../components/slices"
import { graphql } from "gatsby"
import { PageProps } from "gatsby"
import { renderMetaTags } from "../utils"
import { Helmet } from "react-helmet"

const Homepage = ({ data, path }: PageProps) => {
  const { title, subtitle, image, button, homepageBlocks, seoMetaTags } =
    data.datoCmsHomepage

  const heroProps = {
    title,
    subtitle,
    mainImage: image,
    button,
    variation: "large",
  }

  return (
    <Layout>
      {seoMetaTags.tags && <Helmet>{renderMetaTags(seoMetaTags.tags)}</Helmet>}
      <Hero {...heroProps} />
      <SliceRenderer slices={homepageBlocks} />

      {/*<div className="[ section -no-margin-bottom ] product-feature">
        <div className="product-feature__content">
          <h3 className="title h2 -white -underline">
            <span>We supply ## XYZ ##</span>
          </h3>
          <p>
            ## XYZ ## was a natural choice for Bijou Creative Hair, as the brand
            values mirror ours so incredibly well. ## XYZ ## value
            professionalism, integrity, ethics and the idea of connecting
            beauty, environment and well-being.
          </p>
          <p>
            ## XYZ ## never have, and never will conduct animal testing on their
            products, and they tirelessly promote alternative methods to end all
            animal testing in the beauty industry.
          </p>  
        </div>
        <div className="product-feature__image">
           <img src="https://www.fretwellbennett.com/files/xAVEDA-Products.jpg.pagespeed.gp+jp+pj+ws+js+rj+rp+ri+rm+cp+md+im=20.ic.5Vka4ajXSi.jpg" />
        </div>
  </div> */}

      <Map />
      {/* 
      <div className="section -no-margin social-hashtag">
        <div className="[ container ]">
          <div className="social-hashtag__inner">
            <p>#bijoucreativehair</p>
            <p>Follow us online and share your salon look</p>
            <p>Call</p>
            <p>Instagram</p>
            <p>Facebook</p>
            <p>Contact us</p>
          </div>
        </div>
</div> */}
    </Layout>
  )
}

export const query = graphql`
  query Homepage {
    datoCmsHomepage {
      title
      subtitle
      image {
        url
      }
      seoMetaTags {
        tags
      }
      homepageBlocks {
        __typename
        ... on DatoCmsText {
          id
          title
          text {
            value
          }
          backgroundText
        }
        ... on DatoCmsTextBoxWithImage {
          id
          title
          content
          image {
            url
          }
          link {
            ... on DatoCmsStandardContentPage {
              url
            }
          }
          linkText
          alignment
          originalId
        }
        ... on DatoCmsServiceOverview {
          id
          title
          content
          featuredServices {
            id
          }
          showAllServices
        }
        ... on DatoCmsMeetTheTeam {
          title
          subtext
          backgroundImage {
            url
          }
          id
          link {
            url
          }
        }
        ... on DatoCmsGalleryBlock {
          title
          content
          gallery {
            url
            ... on DatoCmsFileField {
              url
            }
          }
          link {
            model {
              name
            }
            ... on DatoCmsGallery {
              id
              url
            }
          }
          use4MostRecentGalleryEntries
        }
      }
    }
  }
`

export default Homepage
